import axios  from 'utils/axios'
// import Qs from 'qs'
/**
 * 获取活动列表
 * @param {*} token
 * @param {*} topicId
 * @returns
 */

 export const getTopicList = (topicId) => {
  return new Promise((resolve,reject)=>{
      let url = '/api/blade-content/component'; 
      axios.get(url,{params:{topicId:topicId}})
      .then((data)=>{
          var result =data;
          return  resolve(result)
      })
      .catch((err)=>{
          reject(err)
      })
  })
}

  // 购物车手动领取优惠券  /blade-coupon/coupon/baseInfo/receiveCoupon    couponId    get
  export const GetCoupon = (couponId) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-coupon/coupon/baseInfo/receiveCoupon'; 
        axios.get(url,{params:{id:couponId}})
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }


