<!-- 组件3   3图 -->
<template>
     <div class="ThemeOne" @click="jumpUrl(ItemDate.componentForwardType)">
         <img :src="ItemDate.pictureAndLinkVOS[0].pic" alt="">
    </div>
</template>
<script>
 import {
    GetCoupon
  } from '../../api/topicPage.js'

  export default {
    name: "ThemeOne",
       inject:['reload'],
    data() {
      return {
        access_token:''
      }
    },
    components: {
  },
    props: {
        // 滚动数组
        ItemDate: {
            type: Object
        },
         token: {
            type: String
        },
    },
    created() {
       console.log('ThemeOne',this.ItemDate)
      //  alert(this.access_token)
    },
    methods: {
       // 手动领取优惠券
    getCouponFun(couponId) {
      GetCoupon(couponId).then((data) => {
          if(data.data.code==200){
             this.$message({
              message:data.data.msg,
              type: "success",
            });
          }else{
              this.$message({
              message:"已领取",
              type: "success",
            });
        }
      })
    },
        jumpUrl(UrlType){
          // 跳转类型 1：链接 2：优惠券 3：商品 4：不跳转
          if(UrlType==1){
               window.location.href=this.ItemDate.pictureAndLinkVOS[0].link
          }else if(UrlType==2){
            // 领取优惠券
             let couponId=this.ItemDate.pictureAndLinkVOS[0].couponVOS[0].id
              this.getCouponFun(couponId)
          }else if(UrlType==3){
            this.jumpDetail(this.ItemDate.pictureAndLinkVOS[0].productVOS[0].id,this.ItemDate.pictureAndLinkVOS[0].productVOS[0].isRecentExpiration)
          }
        },
       // 点击跳转详情页
      jumpDetail(id, isRecentExpiration) {
         this.$router.push({ name: "ProductDetail", query: { id: id ,isRecentExpiration:isRecentExpiration } });
      },


        
    },
     //   注册方法
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
.ThemeOne {
    width:100%;
    display: block;
    height: auto;
    cursor: pointer;
    img{
        display: block;
        width:100%;
        height:auto;
    }
  }
</style>