<!-- 618 主页面 -->
<template>
     <div class="ActivityPage">
            <!-- 11：单图 22：左右 33：左_右上下 41：单商品 52：双商品' -->
        <div v-if="type===11">
           <ThemeOne :ItemDate="item"></ThemeOne>
        </div>
        <div v-if="type===22">
           <ThemeTwo :ItemDate="item"></ThemeTwo>
        </div>
         <div v-if="type===33">
           <ThemeThree :ItemDate="item"></ThemeThree>
        </div>
         <div v-if="type===41">
           <ThemeFour :ItemDate="item"></ThemeFour>
        </div>
         <div v-if="type===52">
           <ThemeFive :ItemDate="item"></ThemeFive>
        </div>
    </div>
</template>
<script>
import ThemeOne from "./ThemeOne.vue";
import ThemeTwo from "./ThemeTwo.vue";
import ThemeThree from "./ThemeThree.vue";
import ThemeFour from "./ThemeFour.vue";
import ThemeFive from "./ThemeFive.vue";
  export default {
    name: "ActivityPage",
    data() {
      return {
          item:{},
          type:0,
      }
    },
    components: {
       ThemeOne,
       ThemeTwo,
       ThemeThree,
       ThemeFour,
       ThemeFive
    },
    props: {
      ItemDate: {
          type: Object
      },
    },
    created() {
        this.item=this.ItemDate
        this.type=this.ItemDate.componentType
        console.log('ItemDate',this.ItemDate.componentType)
    },

    methods: {

    },
     //   注册方法
    mounted() {

    }
  }
</script>
<style>
.ActivityPage{
    width:100%;
    height: auto;
    color: #333;
    font-size: 0.12rem;
    line-height: 0.21rem;
}
</style>