<!-- 组件2   2图 -->
<template>
     <div class="ThemeTwo">
       <div class="imgBox" v-for="(item, index) of ItemDate.pictureAndLinkVOS" :key="index"  @click="jumpUrl(ItemDate.componentForwardType,item)">
         <img :src="item.pic" :alt="item.couponName">
       </div>
       <div class="clear"></div>
    </div>
</template>
<script>
 import {
    GetCoupon
  } from '../../api/topicPage.js'
  
  export default {
    name: "ThemeTwo",
    data() {
      return {
      }
    },
     components: {
    
  },
    props: {
        // 滚动数组
        ItemDate: {
            type: Object
        },

    },
    created() {
       console.log('ThemeTwo',this.ItemDate)
    },
    methods: {
    // 手动领取优惠券
    getCouponFun(couponId) {
      GetCoupon(couponId).then((data) => {

        console.log(data.data)
          if(data.data.code==200){
             this.$message({
              message:data.data.msg,
              type: "success",
            });
          }else{
              this.$message({
              message:"已领取",
              //  message:data.data.msg,
              type: "success",
            });
        }
      })
    },
        jumpUrl(UrlType,item){
          // 跳转类型 1：链接 2：优惠券 3：商品 4：不跳转
          if(UrlType==1){
              this.url = item.link;

              console.log(this.url)
              window.location.href=this.url
          }else if(UrlType==2){
            // 领取优惠券
            // alert('2优惠券')
             let couponId=item.couponVOS[0].id
             this.getCouponFun(couponId)
          }else if(UrlType==3){
            // 3：跳转商品详情  
            this.jumpDetail(item.productVOS[0].id,item.productVOS[0].isRecentExpiration)
          }
        },
         // 点击跳转详情页   
      jumpDetail(id, isRecentExpiration) {
          this.$router.push({ name: "ProductDetail", query: { id: id ,isRecentExpiration:isRecentExpiration } });
      },
    },
     //   注册方法
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
.ThemeTwo{
    display: block;
    height: auto;
    width:100%;
    .imgBox{
       padding:0;
          /* width: 1.4rem; */
          width:50%;
          float: left;
          // margin-bottom: 0.08rem;
          cursor: pointer;
      img{
        display: block;
        width:100%;
        height:auto;
      }
    }
    .clear{
      clear: both;
    }
  }
</style>