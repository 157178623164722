<!-- 618活动  TopicPage -->
<template>
<div>
    <div class="topicPage" >
        <div v-if="datasList.length>0">
            <div  v-for="(item, index) of datasList" :key="index">
                  <ActivityPage :ItemDate="item"></ActivityPage>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ActivityPage from "../components/ActivityTheme/ActivityPage.vue";
import {
  getTopicList
} from '../api/topicPage.js'
  export default {
    name: "topicPage",
      inject:['reload'],
    components: {
       ActivityPage
    },
    data() {
      return {
          TopicId:'',
          datasList:[],//专题页数据
      };
    },
  watch: {
    //   监听路径变化，刷新页面
    "$route.query.Id": function() {
        this.reload();
    },
  },
    created() {
      this.TopicId=this.$route.query.Id
      getTopicList(this.TopicId).then(res=>{
          this.datasList=res.data.data
          console.log(this.datasList)
           let bread = document.getElementsByClassName('header')
            console.log(bread)
            bread[0].style.display = 'none'
            let bread1 = document.getElementsByClassName('el-footer')
            bread1[0].style.display = 'none'
      })

    },
    // beforeRouteLeave (to, form, next) { // 离开本页面时恢复面包屑
    //     let bread = document.getElementsByClassName('header')
    //     bread[0].style.display = ''
    //     let bread1 = document.getElementsByClassName('el-footer')
    //       bread1[0].style.display = ''
    //     next()
    // },
    methods: {
        // http://172.16.0.239:8081/#/topicPage?Id=666555
        //跳转路径后，获取topicId

    

    },
    mounted() {
       
    }
  }
</script>
<style  lang="less" scoped>

.topicPage{
    min-width:950px;
    margin:0 auto;
    display: block;
    height: auto;
    /* width: calc(100% - 0.24rem);
    padding: 0.12rem; */
    /* border-radius: 0.1rem; */
    color: #333;
    font-size: 0.12rem;
    line-height: 0.21rem;
    text-align: center;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
  }
</style>