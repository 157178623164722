<!-- 组件1   单图 -->
<template>
     <div class="ThemeThree">
        <div class="leftContent" v-if='ItemDate.pictureAndLinkVOS[0]' @click="jumpUrl(ItemDate.componentForwardType,ItemDate.pictureAndLinkVOS[0])">
         <img :src="ItemDate.pictureAndLinkVOS[0].pic" alt="" />
        </div>
        <div class="content">
          <div  v-if='ItemDate.pictureAndLinkVOS[1]' @click="jumpUrl(ItemDate.componentForwardType,ItemDate.pictureAndLinkVOS[1])">
           <img :src="ItemDate.pictureAndLinkVOS[1].pic" alt="" />
          </div>
          <div v-if='ItemDate.pictureAndLinkVOS[2].pic' @click="jumpUrl(ItemDate.componentForwardType,ItemDate.pictureAndLinkVOS[2])">
            <img :src="ItemDate.pictureAndLinkVOS[2].pic" alt="" />
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
import {
    GetCoupon
  } from '../../api/topicPage.js'

  export default {
    name: "ThemeThree",
    data() {
      return {
      }
    },
       components: {
  },
    props: {
        // 滚动数组
        ItemDate: {
            type: Object
        },
         token: {
            type: String
        },
    },
    created() {
       console.log('ThemeThree',this.ItemDate)
    },
    methods: {
       // 手动领取优惠券
   getCouponFun(couponId) {
      GetCoupon(couponId).then((data) => {

        console.log(data.data)
          if(data.data.code==200){
             this.$message({
              message:data.data.msg,
              type: "success",
            });
          }else{
              this.$message({
              message:"已领取",
              //  message:data.data.msg,
              type: "success",
            });
        }
      })
    },
        jumpUrl(UrlType,item){
          // 跳转类型 1：链接 2：优惠券 3：商品 4：不跳转
          if(UrlType==1){
                       this.url = item.link;
               window.location.href= this.url 
          }else if(UrlType==2){
            // 领取优惠券
            //  this.$toast('2优惠券')
             let couponId=item.couponVOS[0].id
             this.getCouponFun(couponId)
          }else if(UrlType==3){
              // this.$toast('3商品')
            this.jumpDetail(item.productVOS[0].id,item.productVOS[0].isRecentExpiration)
          }
        },
         // 点击跳转详情页   js跳转app
      jumpDetail(id, isRecentExpiration) {
         this.$router.push({ name: "ProductDetail", query: { id: id ,isRecentExpiration:isRecentExpiration } });
      },
    },
     //   注册方法
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
.ThemeThree {
    width:100%;
    display: block;
    height: auto;
   .leftContent {
      height: 100%;
      width:40%;
      height:500px;
      float: left;
      overflow: hidden;
       img {
          width: 100%;
          height: 100%;
          float: left;
            cursor: pointer;

        }
    }
    .content {
      height: 100%;
      /* width: 1.56rem; */
      width:60%;
      float: right;
      overflow: hidden;
      div:nth-child(1){
        width: 100%;
        height:250px;
        img {
          width: 100%;
          height: 100%;
          float: left;
            cursor: pointer;

        }
      }
      div:nth-child(2) {
        width: 100%;
        height:250px;
          img {
          width: 100%;
          height: 100%;
          float: left;
            cursor: pointer;

        }
      }
    }

    .clear{
      clear: both;
    }
  }
</style>